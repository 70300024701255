import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from './FormContext';
import renderFormNode from './renderFormNode';
import components from './components';
import makeName from './makeName';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { AddCircleOutline, DeleteOutline, RemoveCircleOutline } from '@material-ui/icons';
import { Divider, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const ListInput = ({ item }) => {
  console.debug('ListInput', item);
  const classes = useStyles();
  const { name, value, onChange } = useContext(FormContext);

  const list = get(value, 'list', [{ list: [components()[item.type].defaultValue], progress: 0 }]);

  const node = useMemo(() => renderFormNode(item), [item]);

  const add = e => {
    e.preventDefault();
    onChange({ list: [...list, components()[item.type].defaultValue], progress: 0 });
  };

  const remove = index => e => {
    e.preventDefault();
    const nextList = [...list];
    nextList.splice(index, 1);
    onChange({ list: nextList, progress: 0 });
  };

  return (
    <div>
      <ul className={classes.list}>
        {list.map((v, i) => (
          <li key={i}>
            <Paper variant="outlined" style={{ display: 'flex' }}>
              <Box px={2} display="flex" alignItems="center">
                <Typography color="textSecondary">{i + 1}.</Typography>
              </Box>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <Box pl={2} py={2} pr={1} flex={1}>
                <div style={{ flex: 1 }}>
                  <FormContext.Provider
                    value={{
                      name: makeName(name, i),
                      value: get(value, ['list', i], components()[item.type].defaultValue),
                      onChange: v =>
                        onChange(
                          set(set(cloneDeep(value || components()[item.type].defaultValue), ['list', i], v), 'progress', 0)
                        ),
                    }}
                  >
                    {node}
                  </FormContext.Provider>
                </div>
              </Box>
              <Box py={2} pr={1} display="flex" alignItems="center">
                <IconButton color="secondary" onClick={remove(i)} startIcon={<RemoveCircleOutline />}>
                  <DeleteOutline />
                </IconButton>
              </Box>
            </Paper>
          </li>
        ))}
      </ul>
      <Button onClick={add} startIcon={<AddCircleOutline />} color="primary">
        <FormattedMessage id="questionnaire.list.add" defaultMessage={item.props.addNewLabel || 'Add New Item'} />
      </Button>
    </div>
  );
};

ListInput.defaultValue = undefined;

export default ListInput;
