import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import isObject from 'lodash/isObject';
import FormContext from '../FormContext';
import renderFormNode from '../renderFormNode';
import makeName from '../makeName';
import CloseIcon from '@material-ui/icons/Close';
import PatientName from '../PatientName';

import { useMutation, useQuery } from 'react-apollo-hooks';
import { clearSignature, forms as formsQuery, signature as signatureQuery } from '../../../graphql/schema/form';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import {
  Button,
  Container,
  Divider,
  Drawer,
  Fab,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import LocalePicker from '../../LocalePicker';
import { CheckCircle, RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import SectionContext from './SectionContext';
import TranslatedMessage from '../TranslatedMessage';
import { getFormType } from '../utils/util';
import { useProcedureContext } from '../ProcedureContext';
import { filterSections, shouldRenderSection } from '../utils/shouldRenderSection';

const useStyles = makeStyles(theme => ({
  form: {
    flex: 3,
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1301,
  },
  drawer: {
    width: '100vw',
    fontSize: '1rem',
  },
  sticky: {
    position: 'sticky',
    top: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    flex: 1,
    alignSelf: 'flex-start', // required to enable sticky behavior inside a flex parent
  },
  sectionDone: {
    color: green['A400'],
  },
}));

const LayoutControl = styled.div`
  display: flex;
`;

const FormContent = styled.div`
  &.hidden {
    display: none;
    opacity: 0;
  }
`;

const SectionNavigation = ({ sections, onClick, value }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <List component="nav">
        {sections.map(([key, { name, body: node }], i) => {
          const weight = get(node, 'weight', 0);
          const progress = get(value, ['sections', key, 'progress'], 0);
          const done = progress >= weight;
          return (
            <ListItem key={key} button onClick={() => onClick(i)}>
              <ListItemIcon>
                {done ? (
                  <CheckCircle className={classes.sectionDone} fontSize="large" />
                ) : (
                  <RadioButtonUncheckedTwoTone color="disabled" fontSize="large" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary">
                    <FormattedMessage id={name} defaultMessage={name} />
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Box my={2}>
        <LocalePicker compact />
      </Box>
    </Fragment>
  );
};

const SectionsInput = ({ sections: allSections, ...props }) => {
  const classes = useStyles();
  const procedureContext = useProcedureContext();
  const sections = filterSections(procedureContext, allSections || []);

  const [error, setError] = useState(null);

  const onError = () => {
    setError('Please fill in the required fields');
  };

  const { type, name, value, onChange, save, complete, procedureId } = useContext(FormContext);
  const {
    data: { procedureForms: forms = [] },
  } = useQuery(formsQuery, { variables: { procedureId, formType: getFormType(type) } });

  const clearSignatureMutation = useMutation(clearSignature);
  const isDocumentSigned = forms.filter(_ => _.shouldSign).some(row => !!row.isSigned);

  const [currentSection, setCurrentSection] = useState(0);
  const [showNavigation, setShowNavigation] = useState(false);

  const [agreedToAnnex, setAgreedToAnnex] = useState(null);
  const [annexPrompt, setAnnexPrompt] = useState(false);

  const [key, { name: sectionName, body: node, condition }] = sections[currentSection];

  const handleClearSignature = async () => {
    try {
      await clearSignatureMutation({
        variables: { procedureId, formType: getFormType(type) },
        refetchQueries: [
          { query: signatureQuery, variables: { procedureId, formType: getFormType(type) } },
          { query: formsQuery, variables: { procedureId, formType: getFormType(type) } },
        ],
      });
      setAgreedToAnnex(true);
    } catch (e) {
      console.log(e);
    }
  };

  const promptChange = operation => {
    if (isDocumentSigned) {
      if (agreedToAnnex === null) {
        setAnnexPrompt(true);
      }

      if (agreedToAnnex) {
        return operation();
      }
    } else {
      return operation();
    }
  };

  const handleChange = v => {
    setError(null);
    const nextValue = set(isObject(value) ? cloneDeep(value) : {}, ['sections', key], v);
    const progress = Object.values(nextValue.sections).reduce((a, b) => a + get(b, 'progress', 0), 0);
    promptChange(() => onChange(set(nextValue, 'progress', progress)));
  };

  const goBack = async () => {
    await save();
    setCurrentSection(currentSection - 1);
    window.scrollTo(0, 0);
  };

  const finish = async () => {
    await save();
    complete();
  };

  const proceed = async () => {
    setError(null);
    await save();
    setCurrentSection(currentSection + 1);
    window.scrollTo(0, 0);
  };

  const onNavigationClick = async i => {
    await save();
    setCurrentSection(i);
    setShowNavigation(false);
  };

  const ref = useRef();

  const refNav = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        if (!showNavigation) {
          ref.current.classList.add('hidden');
        } else {
          ref.current.classList.remove('hidden');
        }
      }
      if (refNav.current) {
        if (showNavigation) {
          refNav.current.classList.add('hidden');
        } else {
          refNav.current.classList.remove('hidden');
        }
      }
    }, 250);
    return () => clearTimeout(timer);
  }, [showNavigation]);

  return (
    <LayoutControl>
      <ConfirmationDialog
        open={annexPrompt}
        setOpen={setAnnexPrompt}
        title={
          <FormattedMessage id="questionnaire.sections.annex.confirmation.title" defaultMessage="Confirm action" />
        }
        text={
          <FormattedMessage
            id="questionnaire.sections.annex.confirmation.text"
            defaultMessage="You already signed premedical forms. By changing questionnaire you will need to resign the forms. Do you want to edit?"
          />
        }
        onConfirm={handleClearSignature}
        onDecline={() => setAgreedToAnnex(null)}
      />
      <Hidden smDown implementation="css" className={classes.sticky}>
        <SectionNavigation sections={sections} value={value} onClick={onNavigationClick} />
      </Hidden>

      {!showNavigation && (
        <Container maxWidth="sm">
          <SectionContext>
            {validateThen => (
              <FormContent ref={refNav}>
                <Typography variant="h2" gutterBottom>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" mb={3}>
                    <PatientName procedureId={procedureId} />
                    <FormattedMessage id={sectionName} defaultMessage={sectionName} />
                  </Box>
                </Typography>
                <FormContext.Provider
                  value={{
                    name: makeName(name, key),
                    value: get(value, ['sections', key]),
                    onChange: handleChange,
                  }}
                >
                  {renderFormNode(node, {
                    key,
                    section: key,
                    ...props,
                  })}
                </FormContext.Provider>
                <Grid container spacing={2}>
                  {currentSection > 0 && (
                    <Grid item sm xs={12}>
                      <Button size="large" onClick={goBack} fullWidth>
                        <FormattedMessage id="questionnaire.sections.back" defaultMessage="Back" />
                      </Button>
                    </Grid>
                  )}

                  <Grid item sm xs={12}>
                    {currentSection >= sections.length - 1 ? (
                      <Button color="primary" size="large" onClick={validateThen(finish, onError)} fullWidth>
                        <FormattedMessage id="questionnaire.sections.save.finish" defaultMessage="Save & Finish" />
                      </Button>
                    ) : (
                      <Button
                        color={error ? 'secondary' : 'primary'}
                        size="large"
                        onClick={error ? proceed : validateThen(proceed, onError)}
                        fullWidth
                      >
                        <FormattedMessage
                          id={
                            error
                              ? 'questionnaire.sections.save.continueAnyway'
                              : 'questionnaire.sections.save.continue'
                          }
                          defaultMessage={error ? 'Save Anyway & Continue' : 'Save & Continue'}
                        />
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {!!error && (
                  <FormHelperText style={{ color: 'red' }}>
                    <TranslatedMessage message={error} />
                  </FormHelperText>
                )}
              </FormContent>
            )}
          </SectionContext>
        </Container>
      )}

      <Hidden mdUp>
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="open menu"
          onClick={() => setShowNavigation(!showNavigation)}
        >
          {showNavigation ? <CloseIcon /> : <MenuIcon />}
        </Fab>

        <Drawer
          anchor="left"
          open={showNavigation}
          classes={{ paper: classes.drawer }}
          onClose={() => setShowNavigation(false)}
        >
          <SectionNavigation sections={sections} value={value} onClick={onNavigationClick} />
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Box flex={1} />
      </Hidden>
    </LayoutControl>
  );
};

SectionsInput.defaultValue = { sections: {}, progress: 0 };

export default SectionsInput;
